<template>
  <div class="royalty-report">
    <EbpCard>
      <b-row class="my-4" align-v="end">
        <!-- Date range -->
        <b-col :md="3" class="mb-4 mb-md-0">
          <p class="mb-1">{{ $t("date-range") }}</p>
          <DatePicker
            type="month"
            v-model="date_range"
            :clearable="false"
            range
            :placeholder="$t('date-range')"
            valueType="format"
            format="MMM-YYYY"
          />
        </b-col>
        <b-col md="3" class="mb-4 mb-md-0">
          <p class="mb-1">{{ $t("books") }}</p>
          <AsyncTreeSelect
            :disabled="loading"
            v-model="titles"
            multiple
            :async="$isAdmin"
            :options="titleList"
            internal-search
            internal-search-key="label"
          />
        </b-col>
        <b-col md="3" class="mb-4 mb-md-0">
          <p class="mb-1">{{ $t("territories") }}</p>
          <TreeSelect
            :disabled="loading"
            v-model="territories"
            :options="territoryList"
            multiple
          />
        </b-col>
        <b-col md="3" class="mb-4 mb-md-0">
          <p class="mb-1">{{ $t("sales-channels") }}</p>
          <TreeSelect
            :disabled="loading"
            v-model="salesChannels"
            :options="salesChannelList"
            multiple
          />
        </b-col>
        <b-col md="3" class="mb-4 mb-md-0">
          <p class="mb-1 mt-2">{{ $t("authors") }}</p>
          <AsyncTreeSelect
            :disabled="loading"
            :options="authorList"
            v-model="authors"
            internal-search
            internal-search-key="first_name"
            multiple
            track-by="id"
            label="first_name"
            :async="$isAdmin"
          />
        </b-col>
        <b-col md="2" class="mb-4 mb-md-0" v-if="$isAdmin">
          <p class="mb-1 mt-2">{{ $t("client") }}</p>
          <AsyncTreeSelect
            v-model="clients"
            :async="$isAdmin"
            action="users/search"
            track-by="id"
            label="name"
            :disabled="loading"
          />
        </b-col>
        <b-col md="2">
          <ebp-button
            v-text="$t('export-csv')"
            @click="exportCSV"
            size="sm"
            :block="isSmallScreen"
            :loading="downloading"
          />
        </b-col>
      </b-row>
    </EbpCard>

    <!-- Chart -->
    <EbpCard :loading="loading">
      <apexchart
        width="100%"
        type="bar"
        :series="series"
        :options="chartOptions"
        height="300px"
      />
    </EbpCard>

    <!-- Table -->
    <div class="royalty-report-table">
      <AdvancedDataTable
        :headers="headers"
        :items="records"
        :paginate="false"
        :showLimit="false"
        :loading-global="loading"
        :showTotal="false"
        hideActions
        sticky-header="60vh"
        :search="false"
        sortable
      >
        <template
          v-for="header in headers"
          :slot="`cell(${header.key || header})`"
          slot-scope="{ item }"
        >
          <b v-if="item.is_total" :key="header.key || header">{{
            item[header.key || header]
              ? formatCurrency(item[header.key || header], "GBP")
              : "-"
          }}</b>
          <span class="text-right" v-else :key="header.key || header">{{
            item[header.key || header]
              ? formatCurrency(item[header.key || header], "GBP")
              : "-"
          }}</span>
        </template>

        <template #cell(sales_month)="{ item }">
          <b v-if="item.is_total">
            {{ item.sales_month }}
          </b>
          <template v-else>
            {{ item.sales_month }}
          </template>
        </template>

        <template #cell(paid)="{ item }" class="text-right">
          <div class="text-right">{{ formatCurrency(item.paid, "GBP") }}</div>
        </template>

        <template #cell(received)="{ item }" class="text-right">
          <div class="text-right">
            {{ formatCurrency(item.received, "GBP") }}
          </div>
        </template>

        <template #cell(outstanding)="{ item }" class="text-right">
          <div class="text-right">
            {{ formatCurrency(item.outstanding, "GBP") }}
          </div>
        </template>

        <template #cell(total_count)="{ item }" class="text-right">
          <div class="text-right">
            <b>{{ formatCurrency(item.total_count, "GBP") }}</b>
          </div>
        </template>
      </AdvancedDataTable>
    </div>
  </div>
</template>

<script>
import salesChannelsCategories from "../../fixtures/sales-channel-categories";
import EbpCard from "../../components/core/ebp-card.vue";
import DatePicker from "../../components/core/DatePicker.vue";
import AdvancedDataTable from "../../components/advanced-data-table";
import { format, subMonths, differenceInMonths, parse } from "date-fns";
import { forEach, find } from "lodash-es";
import TreeSelect from "../../components/core/tree-select";
import addMonths from "date-fns/addMonths";
import FileDownload from "js-file-download";
import AsyncTreeSelect from "@/components/AsyncTreeSelect";

const formatString = "MMM-yyyy";

export default {
  name: "RoyaltyReport",
  components: {
    EbpCard,
    DatePicker,
    TreeSelect,
    AdvancedDataTable,
    AsyncTreeSelect
  },
  data() {
    return {
      date_range: [
        format(subMonths(Date.now(), 12), formatString),
        format(Date.now(), formatString)
      ],
      titles: [],
      territories: [],
      salesChannels: [],
      authors: [],
      type: "all",
      showPaid: true,
      showFree: false,
      loading: false,
      groupedData: [],
      clients: [],
      headers: [
        "sales_month",
        "outstanding",
        "received",
        "paid",
        "total_count"
      ],
      downloading: false
    };
  },
  mounted() {
    this.get();
  },
  methods: {
    async exportCSV() {
      this.downloading = true;

      try {
        const res = await this.$store.dispatch("reports/exportCSV", {
          date_range: this.date_range
            .map(d => format(parse(d, "MMM-yyyy", new Date()), "yyyy-MM"))
            .join(","),
          authors: this.authors.join(","),
          titles: this.titles.join(","),
          territories: this.territories.join(","),
          sales_channels: this.salesChannels.join(",")
        });

        FileDownload(res, "royalty-report-export.csv");
      } catch (err) {
        console.error(err);
      }

      this.downloading = false;
    },
    async get() {
      this.loading = true;

      try {
        const res = await this.$store.dispatch("reports/royaltiesByStatus", {
          period: this.date_range
            .map(d => format(parse(d, "MMM-yyyy", new Date()), "yyyy-MM"))
            .join(","),
          user_ids: this.clients.join(",")
        });

        this.groupedData = res.data;
      } catch (err) {
        console.error(err);
      }

      this.loading = false;
    }
  },
  computed: {
    series() {
      const records = this.records.filter(r => !r.is_total);
      return ["paid", "received", "outstanding"].map(status => ({
        name: this.$t(status),
        data: this.dates.map(d => {
          const row = find(records, r => r.sales_month === d);
          return row ? row[status] || 0 : 0;
        })
      }));
    },
    records() {
      const records = [];

      const totalByStatus = {
        paid: 0,
        outstanding: 0,
        received: 0,
        total_count: 0
      };

      forEach(this.groupedData, (i, period) => {
        const royaltiesTotal = {};
        let total = 0;
        forEach(i, ({ data }) => {
          forEach(data, (books, status) => {
            if (royaltiesTotal[status] == undefined) royaltiesTotal[status] = 0;

            forEach(books, (authors, book_id) => {
              if (this.titles.length && !this.titles.includes(book_id)) return;

              forEach(authors, (scats, author_name) => {
                if (author_name === "title") return;
                if (this.authors.length && !this.authors.includes(author_name))
                  return;

                forEach(scats, (scs, scat) => {
                  forEach(scs, (terrs, sc_name) => {
                    if (
                      this.salesChannels.length &&
                      !this.salesChannels.includes(sc_name) &&
                      !this.salesChannels.includes(scat)
                    )
                      return;

                    forEach(terrs, ({ royalties }, terr_code) => {
                      if (
                        this.territories.length &&
                        !this.territories.includes(terr_code)
                      )
                        return;

                      const r = parseFloat(royalties.toFixed(2));

                      royaltiesTotal[status] += r;
                      total += r;
                      totalByStatus[status] += r;
                      totalByStatus["total_count"] += r;
                    });
                  });
                });
              });
            });
          });
        });

        records.push({
          sales_month: format(parse(period, "yyyy-MM", new Date()), "MMM yyyy"),
          ...royaltiesTotal,
          total_count: total
        });
      });

      records.push({
        is_total: true,
        ...totalByStatus,
        sales_month: this.$t("total-count")
      });

      return records;
    },
    dates() {
      const startDate = parse(this.date_range[0], "MMM-yyyy", new Date());
      const endDate = parse(this.date_range[1], "MMM-yyyy", new Date());
      const diffInMonths = Math.abs(differenceInMonths(startDate, endDate)) + 1;

      return new Array(diffInMonths)
        .fill(0)
        .map((_, i) => format(addMonths(startDate, i), "MMM yyyy"));
    },
    salesChannelsCategories() {
      return ["all", ...salesChannelsCategories];
    },
    titleList() {
      const titles = {};
      forEach(this.groupedData, i => {
        i.forEach(i => {
          forEach(i.data, d => {
            forEach(d, (d, id) => {
              if (!titles[id])
                titles[id] = {
                  id: id,
                  label: d.title
                };
            });
          });
        });
      });
      return Object.values(titles);
    },
    authorList() {
      const authors = {};
      forEach(this.groupedData, i => {
        i.forEach(i => {
          forEach(i.data, d => {
            forEach(d, d => {
              forEach(d, (_, k) => {
                if (k === "title") return;
                if (!authors[k])
                  authors[k] = {
                    id: k,
                    label: k
                  };
              });
            });
          });
        });
      });
      return Object.values(authors);
    },
    salesChannelList() {
      const channels = {};
      forEach(this.groupedData, i => {
        i.forEach(i => {
          forEach(i.data, d => {
            forEach(d, e => {
              forEach(e, (v, k) => {
                if (k === "title") return;
                forEach(v, (v, n) => {
                  if (!channels[n]) {
                    let label = "NO LABEL";
                    if (n[0] !== undefined) {
                      label = n[0].toUpperCase();
                    }
                    channels[n] = {
                      label: label + n.slice(1),
                      id: n,
                      children: {}
                    };
                  }

                  forEach(v, (_, sales_channel_name) => {
                    if (!channels[n].children[sales_channel_name]) {
                      channels[n].children[sales_channel_name] = {
                        label: sales_channel_name,
                        id: sales_channel_name
                      };
                    }
                  });
                });
              });
            });
          });
        });
      });
      return Object.values(channels).map(c => ({
        ...c,
        children: Object.values(c.children)
      }));
    },
    territoryList() {
      const territories = {};
      forEach(this.groupedData, i => {
        i.forEach(i => {
          forEach(i.data, d => {
            forEach(d, d => {
              forEach(d, (v, k) => {
                if (k === "title") return;
                forEach(v, v => {
                  forEach(v, v => {
                    forEach(v, (v, n) => {
                      if (!territories[v.name])
                        territories[n] = {
                          label: v.name,
                          id: n
                        };
                    });
                  });
                });
              });
            });
          });
        });
      });
      return Object.values(territories);
    },
    chartOptions() {
      return {
        chart: {
          fontFamily: "SF UI Text",
          type: "bar",
          height: 350,
          stacked: true,
          toolbar: {
            show: true
          }
        },
        plotOptions: {},
        xaxis: {
          categories: this.dates
        },
        yaxis: {
          labels: {
            formatter: value => {
              return this.formatCurrency(value, "GBP", 2);
            }
          },
          title: {
            text: "Royalties (£GBP)",
            style: {
              fontSize: "14px",
              fontFamily: "SF UI Text"
            }
          }
        },
        legend: {
          position: "right",
          offsetY: 40
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          shared: true,
          intersect: false
        },
        colors: ["#28a745", "#ffc107", "#dc3545"],
        dataLabels: {
          enabled: false
        },
        labels: {
          show: true,
          formatter: val => {
            return this.formatCurrency(val, "GBP");
          }
        }
      };
    }
  },
  watch: {
    type() {
      this.get();
    },
    date_range() {
      this.get();
    },
    clients() {
      this.get();
    }
  }
};
</script>
<style lang="scss">
.royalty-report-table {
  .filters.mb-4 {
    display: none;
  }

  th[role="columnheader"] {
    &:not(:first-of-type) {
      text-align: right;
    }
  }
}
</style>
